<template>
  <div class="pa-3">
    <v-data-table
      :headers="Headers"
      :items="cdps"
      class="elevation-1 tb px-2"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>CDPs INFORMATION</b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          :to="{ name: 'CdpInformation', params: { editUser: { ...item } } }"
          icon
          x-small
          class="mr-2"
          title="Click to edit"
          color="black"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-icon
          icon
          small
          @click="del(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon>
      </template>
     
    </v-data-table>
     
    <vue-excel-xlsx
       class="mt-2 text-center"
      :data="cdps"
      :columns="exceldatas"
      :filename="'Cdp Data'"
      :sheetname="'Cdp Sheets'"
    >
      <v-btn small rounded class="white--text" color="primary">
        Export to Excel</v-btn
      >
    </vue-excel-xlsx>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { cdpData } from "@/excels";
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "item",
  mixins: [snackMixin],
  data() {
    return {
      exceldatas: cdpData,
      search: "",
      Headers: [
        { text: "Ref No", value: "CDPRef" },
        { text: "Community/Group Name", value: "GroupName" },
        { text: "Type of Project", value: "projects" },
        { text: "Environmental Screening Conducted", value: "EnvironmentScreen" },
        { text: "Social Screening Conducted", value: "SocialScreen" },
        { text: "Environmental Audit Conducted", value: "EnvironmentAudit" },
        { text: "Social Audit Conducted", value: "SocialAudit" },
        { text: "Community Conflict", value: "CommConflict" },
        { text: "VLD Protocol Developed", value: "VLDProtocol" },
        { text: "Date of Sentization", value: "sentization" },
        { text: "Need Assessment Date", value: "AssessmentDate" },
        { text: "CPMC/GPMC Election Date", value: "ElectionDate" },
        { text: "Desk Appraisal Date", value: "DeskDate" },
        { text: "LGRC Review Date", value: "ReviewDate" },
        { text: "CDP Submission Date", value: "SubmissionDate" },
        { text: "Field Appraisal Date", value: "FieldDate" },
        { text: "Date of Approval", value: "ApprovalDate" },
        { text: "CPMC/GPMC Training Date", value: "TrainingDate" },
        { text: "Project Launch Date", value: "LaunchDate" },
        { text: "Total cost of the CDP", value: "CDPTotalCost" },
        { text: "Status", value: "CDPStatus" },
        { text: "CDP Completed Date", value: "CompletedDate" },
        { text: "Approved MPs/GMPs 1", value: "ApprovedMPs1" },
        { text: "Approved MPs/GMPs 2", value: "ApprovedMPs2" },
        { text: "Approved MPs/GMPs 3", value: "ApprovedMPs3" },
        { text: "Created By", value: "created_by" },
        { text: "Modified By", value: "modified_by" },
        { text: "Actions", value: "actions" },
      ],
      cdps: [],
    };
  },

  created() {
    apiClient
      .get("/cdps_info?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.cdps = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    del(item) {
      const ok = confirm(`Are sure you want to delete ${item.CDPRef}?`);
      if (ok) {
        this.displayMsg(`Deleting ${item.CDPRef}, please wait...`);
        apiClient
          .delete(`/cdps_info?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(
                `${item.CDPRef} not successfully deleted`,
                "error"
              );
              return;
            } else {
              this.cdps = this.cdps.filter((pt) => pt.id !== item.id);
              this.displayMsg(`Successfully deleted ${item.CDPRef} `);
            }
          })
          .catch((err) => this.displayMsg(`${err.message}`, "error"));
      }
    },
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
